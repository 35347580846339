// TO-DO: build vis config dynamically
export const ATTRIBUTION_LOOKS_VIS_CONFIG = {
    x_axis_gridlines: false,
    y_axis_gridlines: true,
    show_view_names: false,
    show_y_axis_labels: true,
    show_y_axis_ticks: true,
    y_axis_tick_density: "default",
    y_axis_tick_density_custom: 5,
    show_x_axis_label: true,
    show_x_axis_ticks: true,
    y_axis_scale_mode: "linear",
    x_axis_reversed: false,
    y_axis_reversed: false,
    plot_size_by_field: false,
    trellis: "",
    stacking: "",
    limit_displayed_rows: false,
    legend_position: "center",
    point_style: "none",
    show_value_labels: false,
    label_density: 25,
    x_axis_scale: "auto",
    y_axis_combined: true,
    ordering: "none",
    show_null_labels: false,
    show_totals_labels: false,
    show_silhouette: false,
    totals_color: "#808080",
    y_axes: [
        {
            label: "",
            orientation: "left",
            series: [
                {
                    axisId: "explore_conversion.reach",
                    id: "explore_conversion.reach",
                    name: "Reach"
                },
                {
                    axisId: "explore_conversion.impressions",
                    id: "explore_conversion.impressions",
                    name: "Impressions"
                },
                {
                    axisId: "explore_conversion.avg_frequency",
                    id: "explore_conversion.avg_frequency",
                    name: "Avg Frequency"
                }
            ],
            showLabels: true,
            showValues: true,
            unpinAxis: false,
            tickDensity: "default",
            tickDensityCustom: 5,
            type: "linear"
        },
        {
            label: null,
            orientation: "right",
            series: [
                {
                    axisId: "explore_conversion.impression_converted",
                    id: "explore_conversion.impression_converted",
                    name: "Conversions"
                },
                {
                    axisId: "explore_conversion.unique_conversions",
                    id: "explore_conversion.unique_conversions",
                    name: "Unique Conversions"
                }
            ],
            showLabels: true,
            showValues: true,
            unpinAxis: false,
            tickDensity: "default",
            tickDensityCustom: 5,
            type: "linear"
        },
        {
            label: null,
            orientation: "right",
            series: [
                {
                    axisId: "explore_conversion.unique_conversion_rate",
                    id: "explore_conversion.unique_conversion_rate",
                    name: "Unique Conversion Rate"
                }
            ],
            showLabels: true,
            showValues: true,
            unpinAxis: false,
            tickDensity: "default",
            tickDensityCustom: 5,
            type: "linear"
        }
    ],
    x_axis_zoom: true,
    y_axis_zoom: true,
    hidden_series: [],
    series_types: {
        "explore_conversion.impression_converted": "line",
        "explore_conversion.unique_conversion_rate": "line",
        "explore_conversion.unique_conversions": "line"
    },
    hidden_pivots: {},
    type: "looker_column",
    defaults_version: 1
};
