import { dealToService, serviceToDeal } from "./deals";
/**
 * Converts an array of `Deal` objects to an array of `ServiceDeal` objects.
 *
 * @param inventories - An array of grouped `Deal` objects representing the inventories.
 * @returns An array of `ServiceDeal` objects.
 */
export const inventoriesToServiceInventories = (inventories) => {
    return inventories?.map((deal) => dealToService(deal));
};
/**
 * Converts an array of ServiceDeal objects to an array of Deal objects.
 *
 * @param inventories - An array of grouped ServiceDeal objects to be converted.
 * @returns An array of Deal objects.
 */
export const serviceInventoriesToInventories = (inventories) => {
    return inventories?.map((deal) => serviceToDeal(deal));
};
/**
 * Enum representing different types of inventory media/video.
 * Shared bewteen Product and Deals models.
 * @enum {string}
 */
export var ServiceInventoryMediaType;
(function (ServiceInventoryMediaType) {
    ServiceInventoryMediaType["NONE"] = "NONE";
    ServiceInventoryMediaType["VIDEO"] = "VIDEO";
    ServiceInventoryMediaType["AUDIO"] = "AUDIO";
    ServiceInventoryMediaType["DISPLAY"] = "DISPLAY";
})(ServiceInventoryMediaType || (ServiceInventoryMediaType = {}));
export var InventoryMediaType;
(function (InventoryMediaType) {
    InventoryMediaType["VIDEO"] = "Video";
    InventoryMediaType["AUDIO"] = "Audio";
    InventoryMediaType["DISPLAY"] = "Display";
})(InventoryMediaType || (InventoryMediaType = {}));
export var ServiceInventoryVideoType;
(function (ServiceInventoryVideoType) {
    ServiceInventoryVideoType["VT_NONE"] = "VT_NONE";
    ServiceInventoryVideoType["OTT"] = "OTT";
    ServiceInventoryVideoType["OLV"] = "OLV";
    ServiceInventoryVideoType["OTV"] = "OTV";
    ServiceInventoryVideoType["DOOH"] = "DOOH";
})(ServiceInventoryVideoType || (ServiceInventoryVideoType = {}));
export var InventoryVideoType;
(function (InventoryVideoType) {
    InventoryVideoType["OTT"] = "OTT";
    InventoryVideoType["OLV"] = "OLV";
    InventoryVideoType["OTV"] = "OTV";
    InventoryVideoType["DOOH"] = "DOOH";
})(InventoryVideoType || (InventoryVideoType = {}));
// video type conversion
const serviceToInventoryVideoMap = {
    [ServiceInventoryVideoType.OTT]: InventoryVideoType.OTT,
    [ServiceInventoryVideoType.OLV]: InventoryVideoType.OLV,
    [ServiceInventoryVideoType.OTV]: InventoryVideoType.OTV,
    [ServiceInventoryVideoType.DOOH]: InventoryVideoType.DOOH,
    [ServiceInventoryVideoType.VT_NONE]: undefined
};
/**
 * Converts a `ServiceInventoryVideoType` to an `InventoryVideoType`.
 *
 * @param video_type - The `ServiceInventoryVideoType` to convert. If `video_type` is `undefined` or `ServiceInventoryVideoType.VT_NONE`, the function returns `undefined`.
 * @returns The corresponding `InventoryVideoType` or `undefined` if the input is `undefined` or `ServiceInventoryVideoType.VT_NONE`.
 */
export const serviceInventoryVideoTypeToInventoryVideoType = (video_type) => {
    return serviceToInventoryVideoMap[video_type];
};
/**
 * Converts an array of `ServiceInventoryVideoType` to an array of `InventoryVideoType`.
 * If the input array is undefined, it returns an empty array.
 *
 * @param video_types - An array of `ServiceInventoryVideoType` or undefined.
 * @returns An array of `InventoryVideoType`.
 */
export const serviceInventoryVideoTypesToInventoryVideoTypes = (video_types) => {
    if (!video_types)
        return [];
    return (video_types
        .map(serviceInventoryVideoTypeToInventoryVideoType)
        //Type Guard: guarantees the elements to be of the InventoryVideoType type.
        .filter((type) => !!type));
};
const inventoryToServiceVideoTypeMap = {
    [InventoryVideoType.OTT]: ServiceInventoryVideoType.OTT,
    [InventoryVideoType.OLV]: ServiceInventoryVideoType.OLV,
    [InventoryVideoType.OTV]: ServiceInventoryVideoType.OTV,
    [InventoryVideoType.DOOH]: ServiceInventoryVideoType.DOOH
};
/**
 * Converts an `InventoryVideoType` to a `ServiceInventoryVideoType`.
 *
 * @param videoType - The inventory video type to convert. If not provided, defaults to `ServiceInventoryVideoType.VT_NONE`.
 * @returns The corresponding `ServiceInventoryVideoType`. If the provided `videoType` is not found in the mapping, returns `ServiceInventoryVideoType.VT_NONE`.
 */
export const inventoryVideoTypeToServiceInventoryVideoType = (videoType) => {
    return videoType
        ? inventoryToServiceVideoTypeMap[videoType] || ServiceInventoryVideoType.VT_NONE
        : ServiceInventoryVideoType.VT_NONE;
};
/**
 * Converts an array of `InventoryVideoType` to an array of `ServiceInventoryVideoType`.
 * If the `mediaTypes` array does not include `InventoryMediaType.VIDEO`, an empty array is returned.
 *
 * @param videoTypes - An array of `InventoryVideoType` or undefined.
 * @param mediaTypes - An optional array of `InventoryMediaType` or undefined.
 * @returns An array of `ServiceInventoryVideoType`.
 */
export const inventoryVideoTypesToServiceInventoryVideoTypes = (videoTypes, mediaTypes) => {
    if (!mediaTypes?.includes(InventoryMediaType.VIDEO)) {
        return [];
    }
    return (videoTypes || [])
        .map(inventoryVideoTypeToServiceInventoryVideoType)
        .filter((type) => type !== ServiceInventoryVideoType.VT_NONE);
};
// mediaType conversion
const serviceToInventoryMediaTypeMap = {
    [ServiceInventoryMediaType.VIDEO]: InventoryMediaType.VIDEO,
    [ServiceInventoryMediaType.AUDIO]: InventoryMediaType.AUDIO,
    [ServiceInventoryMediaType.DISPLAY]: InventoryMediaType.DISPLAY,
    [ServiceInventoryMediaType.NONE]: undefined
};
/**
 * Converts a `ServiceInventoryMediaType` to an `InventoryMediaType`.
 *
 * @param media_type - The media type from the service inventory.
 * @returns The corresponding inventory media type, or `undefined` if the input is `undefined`.
 */
export const serviceInventoryMediaTypeToInventoryMediaType = (media_type) => {
    return serviceToInventoryMediaTypeMap[media_type];
};
/**
 * Converts an array of service inventory media types to an array of inventory media types.
 *
 * @param media_types - An array of `ServiceInventoryMediaType` or undefined.
 * @returns An array of `InventoryMediaType`.
 */
export const serviceInventoryMediaTypesToInventoryMediaTypes = (media_types) => {
    return (media_types || [])
        .map(serviceInventoryMediaTypeToInventoryMediaType)
        .filter((type) => !!type);
};
const inventoryToServiceMediaTypeMap = {
    [InventoryMediaType.VIDEO]: ServiceInventoryMediaType.VIDEO,
    [InventoryMediaType.AUDIO]: ServiceInventoryMediaType.AUDIO,
    [InventoryMediaType.DISPLAY]: ServiceInventoryMediaType.DISPLAY
};
/**
 * Converts an `InventoryMediaType` to a `ServiceInventoryMediaType`.
 *
 * @param mediaType - The media type to convert. If not provided, defaults to `ServiceInventoryMediaType.NONE`.
 * @returns The corresponding `ServiceInventoryMediaType`. If the media type is not found in the map, returns `ServiceInventoryMediaType.NONE`.
 */
export const inventoryMediaTypeToServiceInventoryMediaType = (mediaType) => {
    return mediaType
        ? inventoryToServiceMediaTypeMap[mediaType] || ServiceInventoryMediaType.NONE
        : ServiceInventoryMediaType.NONE;
};
/**
 * Converts an array of `InventoryMediaType` to an array of `ServiceInventoryMediaType`.
 * Filters out any `ServiceInventoryMediaType` that is `ServiceInventoryMediaType.NONE`.
 *
 * @param mediaTypes - An array of `InventoryMediaType` or undefined.
 * @returns An array of `ServiceInventoryMediaType`.
 */
export const inventoryMediaTypesToServiceInventoryMediaTypes = (mediaTypes) => {
    return (mediaTypes || [])
        .map(inventoryMediaTypeToServiceInventoryMediaType)
        .filter((type) => type !== ServiceInventoryMediaType.NONE);
};
