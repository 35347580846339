import { ViewId, KnownOrganizationIds } from "@madhive/mad-sdk";
import { selectCurrentUser } from "appReducers/authReducer";
import LoginView from "components/Login";
import SmithersSkeleton from "components/Reusable/SmithersSkeleton/SmithersSkeleton";
import { Routes } from "lib/constants/routes";
import { getUserPermissions } from "components/Auth/utils";
import { useFeatureFlag, RemoteConfigProperties } from "hooks/useFeatureFlag";
import { Routes as RouterRoutes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { lazy, FC, Suspense, useMemo } from "react";
import { isLocalhost, isSandboxHost } from "lib/utils/env";
import { madTracker } from "frontier/lib/tracker";
import { PeerChanges } from "frontier/lib/components/PeerChanges";
import useManageDealsView from "hooks/useManageDealsView";
import { getLoginBackgroundClassName } from "./utils";

/**
 * These lazy imports allow us to load only the code necessary for the view the
 * user is looking at.
 */
const LoginLocal = lazy(() => import("components/LoginLocal"));
const NotFound = lazy(() => import("../NotFound"));
const ManageUsers = lazy(
  () => import("../../features/ManageUsers/ManageUsers")
);
const ManageLists = lazy(
  () => import("../../features/ManageLists/ManageLists")
);

const ManageCampaigns = lazy(
  () => import("../../features/ManageCampaigns/ManageCampaigns")
);
const TrackingPixels = lazy(
  () => import("../../features/TrackingPixels/TrackingPixels")
);

const CreativeLibrary = lazy(
  () => import("../../features/CreativeLibrary/CreativeLibrary")
);
const UserProfile = lazy(
  () => import("../../features/UserProfile/UserProfile")
);

const Dashboard = lazy(() => import("../../features/Dashboard/Dashboard"));

const ManageAdvertisers = lazy(
  () => import("../../features/ManageAdvertisers/ManageAdvertisers")
);

const ManageAgencies = lazy(() => import("../../features/ManageAgencies"));

const ManageStations = lazy(
  () => import("../../features/ManageStations/ManageStations")
);

const ManageProducts = lazy(
  () => import("../../features/ManageProducts/ManageProducts")
);

const ManageDeals = lazy(() => import("../../frontier/routes/internal/deals"));

const PremionPacing = lazy(
  () => import("../../features/PremionPacing/PremionPacing")
);

const AdbookErrors = lazy(
  () => import("../../features/ScrippsOnly/AdbookErrors/AdbookErrors")
);

const Pacing = lazy(() => import("../../features/Pacing"));

const ManageAudiences = lazy(() => import("../../features/ManageAudiences"));

const AudienceDataProvisioning = lazy(
  () => import("../../features/AudienceDataProvisioning")
);

const CustomReporting = lazy(() => import("../../features/CustomReporting"));

const CryptoDashboard = lazy(
  () => import("../../features/CryptoDashboard/page")
);

const DataStudioReports = lazy(
  () => import("../../features/DataStudioReports/DataStudioReports")
);

const ManagePublishers = lazy(
  () => import("../../features/ManagePublishers/ManagePublishers")
);

const WideOrbitErrors = lazy(
  () => import("../../features/WideOrbit/WideOrbitErrors")
);

// Talon Route
const OOHPlanning = lazy(() => import("../../features/OOH/Planning"));

const Planner = lazy(() => import("../../features/Planner"));

const PlannerAdminTool = lazy(
  () => import("../../frontier/lib/planner-admin-tool")
);

const AdvancedExports = lazy(() => import("../../features/AdvancedExports"));

const FootTrafficAttribution = lazy(
  () => import("../../features/FootTrafficAttribution")
);

const FirstPartyDataOnboarding = lazy(
  () => import("../../features/FirstPartyDataOnboarding")
);

const Admin = lazy(() => import("../../frontier/routes/admin"));

const Kit = lazy(() => import("../../frontier/routes/kit"));

const FiveMinSeparation = lazy(
  () => import("../../frontier/routes/internal/five-min-separation")
);

const ConversionDashboard = lazy(
  () => import("../../features/ConversionDashboard")
);

const Spend = lazy(() => import("../../frontier/routes/insights/spend"));

const Conversion = lazy(
  () => import("../../frontier/routes/insights/conversion")
);

const ReportBuilder = lazy(
  () => import("../../frontier/routes/internal/report-builder")
);

const Reports = lazy(() => import("../../frontier/routes/internal/reports"));

const BIReport = lazy(() => import("../../frontier/routes/internal/bi-report"));

const PacingDashboard = lazy(
  () => import("../../frontier/routes/insights/pacing")
);

const Attribution = lazy(
  () => import("../../frontier/routes/insights/attribution")
);

const SmithersRouter: FC = () => {
  const user = useSelector(selectCurrentUser);
  const { hostname } = window.location;

  const location = useLocation();
  useMemo(() => {
    madTracker.page();
  }, [location.hash, location.pathname, location.search]);

  const canViewManageDeals = useManageDealsView();

  const { canView: canViewFiveMinSeparation } = getUserPermissions(
    ViewId.FIVE_MIN_SEPARATION,
    user?.abilities || []
  );

  const isFiveMinSeparationEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.INTERNAL_FIVE_MIN_SEPARATION
  );

  const { canView: canViewSpend } = getUserPermissions(
    ViewId.SPEND_DASHBOARD,
    user?.abilities || []
  );

  const isAudienceDataProvisioningEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_AUDIENCE_DATA_PROVISIONING
  );

  // Only Madhive admin users can access Audience Data Provisioning
  const canViewAudienceDataProvisioning =
    user?.primaryOrganizationId === KnownOrganizationIds.MADHIVE_DEFAULT &&
    user?.isAdmin;

  const isBiConversionDashboardEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_BI_CONVERSION_DASHBOARD
  );

  const { canView: canViewConversion } = getUserPermissions(
    ViewId.BI_CONVERSION_DASHBOARD,
    user?.abilities || []
  );

  const { canView: canViewPacing } = getUserPermissions(
    ViewId.PACING_BETA,
    user?.abilities || []
  );

  const isPacingBetaEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_PACING_BETA
  );

  const isBiSpendDashboardEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_BI_SPEND_DASHBOARD
  );

  const isSaveAlertsFeatureEnabled = useFeatureFlag(
    RemoteConfigProperties.PEER_CHANGES_SAVE_ALERTS
  );
  return (
    <Suspense fallback={<SmithersSkeleton.Page fullContent />}>
      <div
        className={classNames(
          "Smithers__FillContainer",
          getLoginBackgroundClassName(hostname, user)
        )}
      >
        {isSaveAlertsFeatureEnabled && <PeerChanges />}
        <RouterRoutes>
          <Route path={Routes.LOGIN} element={<LoginView />} />
          <Route path={Routes.LOGIN_LOCAL} element={<LoginLocal />} />
          <Route path={`${Routes.DASHBOARD}/*`} element={<Dashboard />} />
          <Route
            path={`${Routes.CONVERSION_DASHBOARD}/*`}
            element={<ConversionDashboard />}
          />
          <Route path={Routes.HOME} element={<Dashboard />} />
          <Route path={Routes.USER_PROFILE} element={<UserProfile />} />
          <Route path={`${Routes.MANAGE_USERS}/*`} element={<ManageUsers />} />
          <Route
            path={`${Routes.MANAGE_PUBLISHER_GROUPS}/*`}
            element={<ManageLists />}
          />
          <Route
            path={`${Routes.MANAGE_CAMPAIGNS}/*`}
            element={<ManageCampaigns />}
          />
          <Route
            path={`${Routes.TRACKING_PIXELS}/*`}
            element={<TrackingPixels />}
          />
          <Route
            path={`${Routes.CREATIVE_LIBRARY}/*`}
            element={<CreativeLibrary />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path={`${Routes.PACING}/*`} element={<Pacing />} />
          {isPacingBetaEnabled && (canViewPacing || user?.isDev) && (
            <Route
              path={`${Routes.PACING_BETA}/*`}
              element={<PacingDashboard />}
            />
          )}
          <Route
            path={`${Routes.MANAGE_ADVERTISERS}/*`}
            element={<ManageAdvertisers />}
          />
          <Route
            path={`${Routes.MANAGE_AGENCIES}/*`}
            element={<ManageAgencies />}
          />
          <Route
            path={`${Routes.MANAGE_STATIONS}/*`}
            element={<ManageStations />}
          />
          <Route
            path={`${Routes.MANAGE_PRODUCTS}/*`}
            element={<ManageProducts />}
          />
          {canViewManageDeals && (
            <Route
              path={`${Routes.MANAGE_DEALS}/*`}
              element={<ManageDeals />}
            />
          )}
          {isFiveMinSeparationEnabled &&
            (canViewFiveMinSeparation || user?.isDev) && (
              <Route
                path={`${Routes.FIVE_MIN_SEPARATION}/*`}
                element={<FiveMinSeparation />}
              />
            )}
          {((isBiSpendDashboardEnabled && canViewSpend) || user?.isDev) && (
            <Route path={`${Routes.SPEND_DASHBOARD}/*`} element={<Spend />} />
          )}
          {isBiConversionDashboardEnabled &&
            (canViewConversion || user?.isDev) && (
              <Route
                path={`${Routes.BI_CONVERSION_DASHBOARD}/*`}
                element={<Conversion />}
              />
            )}
          <Route
            path={`${Routes.DELIVERY_REPORT}/*`}
            element={<PremionPacing />}
          />
          <Route
            path={`${Routes.ADBOOK_ERRORS}/*`}
            element={<AdbookErrors />}
          />
          <Route
            path={`${Routes.MANAGE_AUDIENCES}/*`}
            element={<ManageAudiences />}
          />
          {isAudienceDataProvisioningEnabled &&
            canViewAudienceDataProvisioning && (
              <Route
                path={`${Routes.AUDIENCE_DATA_PROVISIONING}/*`}
                element={<AudienceDataProvisioning />}
              />
            )}
          <Route
            path={`${Routes.CUSTOM_REPORTING}/*`}
            element={<CustomReporting />}
          />
          <Route
            path={`${Routes.CRYPTO_DASHBOARD}/*`}
            element={<CryptoDashboard />}
          />
          <Route path={`${Routes.OOH_PLANNING}/*`} element={<OOHPlanning />} />
          <Route
            path={`${Routes.DATASTUDIO_REPORTS}/*`}
            element={<DataStudioReports />}
          />
          <Route
            path={`${Routes.MANAGE_PUBLISHERS}/*`}
            element={<ManagePublishers />}
          />
          <Route
            path={`${Routes.WIDE_ORBIT_ERRORS}/*`}
            element={<WideOrbitErrors />}
          />
          <Route path={`${Routes.PLANNER}/*`} element={<Planner />} />
          <Route
            path={`${Routes.PLANNER_ADMIN_TOOL}/*`}
            element={<PlannerAdminTool />}
          />
          <Route
            path={`${Routes.ADVANCED_EXPORTS}/*`}
            element={<AdvancedExports />}
          />
          <Route
            path={`${Routes.WIDE_ORBIT_ERRORS}/*`}
            element={<WideOrbitErrors />}
          />
          <Route path={`${Routes.PLANNER}/*`} element={<Planner />} />
          <Route
            path={`${Routes.ADVANCED_EXPORTS}/*`}
            element={<AdvancedExports />}
          />
          <Route
            path={`${Routes.FOOT_TRAFFIC_ATTRIBUTION}/*`}
            element={<FootTrafficAttribution />}
          />
          <Route
            path={`${Routes.FIRST_PARTY_DATA_ONBOARDING}/*`}
            element={<FirstPartyDataOnboarding />}
          />
          <Route path={`${Routes.ADMIN}/*`} element={<Admin />} />
          {(isLocalhost || isSandboxHost) && (
            <Route path={`${Routes.KIT}/*`} element={<Kit />} />
          )}
          <Route
            path={`${Routes.REPORT_BUILDER}/*`}
            element={<ReportBuilder />}
          />
          <Route
            path={`${Routes.REPORTS}/report/:reportId/*`}
            element={<BIReport />}
          />
          <Route path={`${Routes.REPORTS}/*`} element={<Reports />} />
          <Route path={`${Routes.ATTRIBUTION}/*`} element={<Attribution />} />
        </RouterRoutes>
      </div>
    </Suspense>
  );
};

export default SmithersRouter;
