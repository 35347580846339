import { NotImplementedError } from "../../../../../errors";
import { attributionModelFieldsToQueryDimensions, isAttributionFilter, modelFiltersToLookQuerySort, modelFiltersToQueryFilters } from "../../../../../models/looker/modules/attribution";
import { Handler } from "../../../../handlers";
import { parseInclusionFilter } from "../../../../handlers/filter";
import axios from "axios";
import { ATTRIBUTION_QUERY } from "../query";
import { ATTRIBUTION_LOOKS_VIS_CONFIG } from "./query";
class Looks extends Handler {
    constructor(sdk) {
        super(sdk, "looker-attribution-looks");
        this.lookUrl = `${this.sdk.urls.burnsBaseUrl}/looker/attribution`;
    }
    async findItems(filters) {
        const lookName = parseInclusionFilter(filters, "lookName", ["string"])
            .values()
            .next().value;
        if (!lookName) {
            throw new Error("Look name filter is required");
        }
        const queryBody = this.buildQuery(filters);
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .create(queryBody)
            .then((query) => query.id)
            .then((id) => axios
            .get(`${this.lookUrl}/${lookName}?query_id=${encodeURIComponent(id)}`)
            .then((lookId) => [lookId.data]))
            .catch(() => {
            throw new Error("Failed to fetch look");
        }));
    }
    buildQuery(filters) {
        const fields = attributionModelFieldsToQueryDimensions(filters.fields || []);
        const queryFilters = modelFiltersToQueryFilters(filters.where?.filter((f) => isAttributionFilter(f.field)));
        const sorts = [modelFiltersToLookQuerySort(filters.where)];
        return {
            ...ATTRIBUTION_QUERY,
            fields,
            filters: queryFilters,
            sorts,
            // TO-DO: decide whether to make limit dynamic / configurable
            limit: "15",
            vis_config: ATTRIBUTION_LOOKS_VIS_CONFIG
        };
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Looks;
