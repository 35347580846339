import { NotImplementedError } from "../../../../../errors";
import { parseDateFilter } from "../../../../../models/looker/modules/attribution";
import { Handler } from "../../../../handlers";
import { FILTER_OPTIONS_ADVERTISER_QUERY } from "../query";
export default class Advertiser extends Handler {
    constructor(sdk) {
        super(sdk, "looker-pacing");
    }
    async findItems(filters) {
        const dateFilter = filters.where?.find((filter) => filter.field === "dates");
        if (!dateFilter) {
            throw new Error("Dates filter is required");
        }
        const parsedDateFilter = parseDateFilter(dateFilter);
        const queryBody = {
            ...FILTER_OPTIONS_ADVERTISER_QUERY,
            filters: {
                "billable_agg.filter_date_nyc": parsedDateFilter
            }
        };
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .run(queryBody)
            .then(({ data, queryMeta }) => [
            {
                data: this.formatAdvertiserResponse(data),
                queryMeta
            }
        ])
            .catch((e) => {
            console.error(e);
            throw new Error("Failed to fetch conversion dashboard advertiser filter options");
        }));
    }
    formatAdvertiserResponse(data) {
        return data.map((advertiser) => ({
            name: advertiser["conversion_meta_inst.advertiser__name"],
            id: advertiser["conversion_meta_inst.advertiser__id"]
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
