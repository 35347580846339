/* eslint-disable camelcase */
import { serviceExternalMetaToExternalMeta } from "../../types";
import { getPacingStatus, getPacingType } from "../../utils";
const parseExternalMetadata = (externalMetadataString) => {
    if (!externalMetadataString) {
        return undefined;
    }
    const externalMetadata = JSON.parse(externalMetadataString);
    return serviceExternalMetaToExternalMeta(externalMetadata);
};
const getPacingStrategy = (campaign) => {
    if (!campaign.camp_pacing_type) {
        // For backwards compatibility, if camp_pacing_type is not set, default to impression
        return "impressions";
    }
    return campaign.camp_pacing_type;
};
export const serviceLineItemSummaryToLineItemSummary = (lineItem, campaign) => {
    const pacingStrategy = getPacingStrategy(campaign);
    const pacingType = getPacingType(pacingStrategy === "impressions" ? lineItem.delivered : lineItem.delivered_spend || 0, Date.now(), new Date(lineItem.li_start).getTime(), new Date(lineItem.li_end).getTime());
    return {
        id: lineItem.li_id,
        name: lineItem.li_nam,
        start: lineItem.li_start,
        end: lineItem.li_end,
        owners: campaign.owners,
        sumBooked: lineItem.li_booked,
        delivered: lineItem.delivered,
        deliveredSpend: lineItem.delivered_spend || 0,
        remaining: pacingStrategy === "impressions"
            ? lineItem.li_booked - lineItem.delivered
            : lineItem.li_booked - (lineItem.delivered_spend || 0),
        goalInFilter: lineItem.pacing_goal_filtered,
        isDoubleVerify: lineItem.is_double_verify,
        pacing: lineItem.pacing * 100,
        pacingGoal: lineItem.pacing_goal || 0,
        pacingType,
        pacingStrategy,
        pacingDeviation: lineItem.pacing_deviation * 100,
        pacingStatus: getPacingStatus(lineItem.pacing * 100, pacingType),
        deliveredInFilter: lineItem.delivered_filtered,
        deliveredSpendInFilter: lineItem.delivered_spend_filtered || 0,
        bookedByTodayInFilter: lineItem.pacing_goal_filtered,
        bookedByToday: lineItem.pacing_goal_eod,
        dailySummary: lineItem.daily_summary,
        dailySummarySpend: lineItem.daily_summary_spend || [],
        meta: parseExternalMetadata(lineItem.li_ext_meta),
        parent: lineItem.li_parent_id,
        audioCompletes: lineItem.audio_completes,
        listenThroughRate: lineItem.listen_through_rate,
        videoCompletes: lineItem.video_completes,
        videoCompleteRate: lineItem.video_complete_rate,
        productName: lineItem.product_name
    };
};
export const serviceCampaignSummariesToCampaignSummaries = (campaigns) => {
    const summarySet = new Set();
    const campaignSummaries = [];
    campaigns.forEach((campaign) => {
        const pacingStrategy = getPacingStrategy(campaign);
        const lineItems = campaign.li_summary.map((item) => {
            return serviceLineItemSummaryToLineItemSummary(item, campaign);
        });
        const pacingType = getPacingType(pacingStrategy === "impressions" ? campaign.delivered : campaign.delivered_spend || 0, Date.now(), new Date(campaign.camp_start).getTime(), new Date(campaign.camp_end).getTime());
        // addresses an odd edge case we've seen where the same campaign is returned multiple times
        if (summarySet.has(campaign.camp_id)) {
            return;
        }
        summarySet.add(campaign.camp_id);
        campaignSummaries.push({
            id: campaign.camp_id,
            name: campaign.camp_nam,
            start: campaign.camp_start,
            end: campaign.camp_end,
            owners: campaign.owners,
            meta: parseExternalMetadata(campaign.camp_ext_meta),
            delivered: campaign.delivered,
            deliveredSpend: campaign.delivered_spend || 0,
            sumBooked: campaign.sum_li_booked,
            remaining: pacingStrategy === "impressions"
                ? campaign.sum_li_booked - campaign.delivered
                : campaign.sum_li_booked - (campaign.delivered_spend || 0),
            deliveredInFilter: campaign.delivered_filtered,
            deliveredSpendInFilter: campaign.delivered_spend_filtered || 0,
            goalInFilter: campaign.pacing_goal_filtered,
            pacing: campaign.pacing * 100,
            pacingGoal: campaign.pacing_goal || 0,
            pacingType,
            pacingStrategy,
            isDoubleVerify: !!lineItems.find((item) => item.isDoubleVerify),
            pacingDeviation: campaign.pacing_deviation * 100,
            pacingStatus: getPacingStatus(campaign.pacing * 100, pacingType),
            bookedByToday: campaign.pacing_goal_eod,
            bookedByTodayInFilter: campaign.pacing_goal_filtered,
            lineItems,
            brand: campaign.brand,
            estimate: campaign.estimate,
            mediaType: campaign.media_type,
            audioCompletes: campaign.audio_completes,
            listenThroughRate: campaign.listen_through_rate,
            videoCompletes: campaign.video_completes,
            videoCompleteRate: campaign.video_complete_rate
        });
    });
    return campaignSummaries;
};
