export const ATTRIBUTION_QUERY = {
    view: "explore_conversion",
    fields: [],
    filters: {},
    sorts: ["explore_conversion.unique_conversion_rate desc"],
    limit: "20",
    column_limit: "50",
    model: "client_analytics"
};
export const FILTER_OPTIONS_ADVERTISER_QUERY = {
    view: "explore_conversion_suggests",
    fields: ["conversion_meta_inst.advertiser__name", "conversion_meta_inst.advertiser__id"],
    filters: {
        "billable_agg.filter_date_nyc": ""
    },
    sorts: ["conversion_meta_inst.advertiser__name desc"],
    limit: "5000",
    column_limit: "500",
    model: "client_analytics"
};
export const FILTER_OPTIONS_CAMPAIGN_PIXEL_QUERY = {
    view: "explore_conversion_suggests",
    fields: [
        "conversion_meta_inst.campaign__name",
        "conversion_meta_inst.campaign__id",
        "conversion_meta_beacon.name",
        "conversion_meta_beacon.pk1_id",
        "conversion_meta_inst.lineitem__id"
    ],
    filters: {
        "billable_agg.filter_date_nyc": "",
        "conversion_meta_inst.advertiser__id": ""
    },
    sorts: ["conversion_meta_inst.campaign__name desc"],
    limit: "5000",
    column_limit: "500",
    model: "client_analytics"
};
